#entrance {
	animation-duration: 2.0s;
}
#clock {
	animation-delay: 1s;
	animation-duration: 3.0s;
	position:absolute;
	bottom:30%;
	left:10%;
	color:#fff;
	font-size:200px;
	text-shadow: 0 0 20px yellow;
}
#oDate {
	animation-delay: 1s;
	animation-duration: 3.0s;
	position:absolute;
	bottom:38%;
	left:10%;
	color:#fff;
	font-size:200px;
	text-shadow: 0 0 20px yellow;
}
#me {
  	position: absolute;
  	top: 30%;
	left: 38%;
	text-align: center;
}
#me1 {
	animation-delay: 1s;
	animation-duration: 3.0s;
	color:#fff;
	font-size:4.5em;
	text-shadow: 0 0 20px yellow;
}
#me2 {
	animation-delay: 2s;
	animation-duration: 3.0s;
	color:#fff;
	font-size:4.5em;
	text-shadow: 0 0 20px yellow;
}

#me3 {
	animation-delay: 3s;
	animation-duration: 2s;
	margin-top: 3em;
	color:#fff;
	font-size:3em;
	text-shadow: 0 0 20px yellow;
}

#oDate .sec {
	font-size:0.5em;
}
#oDate .st {
	position:absolute;
	right:2%;
	top:15%;
	font-size:0.25em;
	line-height:1;
}
#can {
	margin:0;
	padding:0;
	position:absolute;
	left:0;
	top:0;
	z-index:999;
}

#CanLine {
	margin:0;
	padding:0;
	position:absolute;
	left:0;
	top:0;
	z-index:998;
}

body {
	padding:0;
	margin:0;
	overflow:hidden;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
canvas {
	padding:0;
	margin:0;
	opacity:1;
}
#sakura{
	opacity:0.2;
}
div.btnbg {
	position:fixed;
	left:0;
	top:0;
}


/*
Design by TEMPLATED
http://templated.co
Released for free under the Creative Commons Attribution License
*/

dt {
	margin-top: 10px; /* 위쪽 여백 */
}

dd {
	margin-inline-start: 0px;
	margin-top: 5px;
}

dl {
	margin-bottom: 5px;
}

ul { 
	margin-left: 20px;
}

li { 
	margin-top: 5px;
}

body {
	margin: 0px 0px 0px 0px;
	padding: 0;
	font-family: Arial, Helvetica, sans-serif;
	color: #4b4b4b;
}

table {
	border-collapse: collapse;
}

th {
	text-align: center; 
	width: 120px;
	font-size: 16px
}

td {
	font-size: 16px
}

h1, h2, h3 {
	margin: 0;
	padding: 0;
	font-weight: 200;
	color: #222222;
}

h1 {
	padding: 50px 0;
	font-weight: 400;
	text-align: center;
}


h2 {
	font-size: 3em;
}

p, ol, ul {
	margin-top: 0px;
	padding: 0px;
}

p, ol {
	line-height: 180%;
}

strong {
}

a:hover {
	text-decoration: none;
}

img {
	margin-left: auto; 
	margin-right: auto; 
	margin-top: 30px;
	margin-bottom: 30px;
	display: block;
}

a img {
	border: none;
}

table.test img, p {
	margin-right: 10px;
	margin-left: 10px;
	margin-bottom: 25px;
}

table.test p {
	font-size: 14px;
	color: #0404B4;
}

img.border {
}

img.alignleft {
	float: left;
	margin-right: 50px;
}

img.alignright {
	float: right;
}

img.aligncenter {
	margin: 0px auto;
}

hr {
	/* display: none; */
}

/** WRAPPER */

#wrapper {
	overflow: hidden;
	background: #FFFFFF;
	box-shadow: 
        inset 0px 11px 8px -20px #CCC,
        inset 0px -11px 8px -10px #CCC;}

.container {
	width: 1200px;
	margin: 0px auto;
}

.clearfix {
	clear: both;
}

/* Header */

#header-wrapper {
	background: #1f31bb;
	overflow: hidden;
	height: 150px;
}

#header {
	width: 1200px;
	height: 150px;
	margin: 0 auto;
	padding: 0px 0px;
}

/* Logo */

#logo {
	float: left;
	width: 310px;
	height: 150px;
	padding: 0px 0px 0px 40px;
}

#logo h1 {
	padding: 50px 0px 0px 0px;
	letter-spacing: -2px;
	font-size: 3.6em;
}

#logo h1 a {
	text-decoration: none;
	color: #FFFFFF;
}


/* Menu */

#menu {
	float: right;
	width: 810px;
	height: 80px;
	padding: 20px 40px 0px 0px;
}

#menu ul {
	float: left;
	margin: 0;
	padding: 40px 0px 0px 0px;
	list-style: none;
	line-height: normal;
}

#menu li {
	float: left;
}

#menu a {
	display: block;
	margin-left: 1px;
	padding: 7px 20px 7px 20px;
	letter-spacing: 1px;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	font-family: 'Oswald', sans-serif;
	font-size: 16px;
	font-weight: 300;
	color: #FFFFFF;
}

/* Page */

#page-wrapper {
	overflow: hidden;
}

#page {
	overflow: hidden;
	width: 1120px;
	margin: 0px auto;
	padding: 50px 40px;
}

/** CONTENT */

#wide-content {
	padding: 50px;
	border: 10px solid #f1f1f1;
}

#wide-content h2 {
	padding: 0px 0px 20px 0px;
	letter-spacing: -1px;
	font-size: 36px;
	color: #222222;
}

/** CONTENT */

#content {
	float: right;
	width: 760px;
	padding: 0px 0px 0px 0px;
}

#content h2 {
	padding: 0px 0px 20px 0px;
	letter-spacing: -1px;
	font-size: 36px;
	color: #222222;
}

#content .subtitle {
	padding: 0px 0px 30px 0px;
	text-transform: uppercase;
	font-family: 'Oswald', sans-serif;
	font-size: 18px;
	color: #81AFC5;
}

/** SIDEBAR */

#sidebar {
	float: left;
	width: 290px;
	padding: 0px 0px 20px 0px;
}

#sidebar h2 {
	padding: 0px 0px 30px 0px;
	letter-spacing: -1px;
	font-size: 2em;
}

/* Footer */

#footer {
	margin: 0 auto;
	padding: 50px 0px 50px 0px;
}

#footer p {
	text-align: center;
	font-size: 12px;
	color: #b0b9ff;
}

#footer a {
	color: #b0b9ff;
}

/* Three Column Footer Content */


#footer-bg {
	overflow: hidden;
	padding: 40px 0px;
	/* background:#E5E0DD url(images/main-bsg.png) repeat; */
}

#footer-content {
	color: #67C8E3;
}

#footer-content h2 {
	margin: 0px;
	padding: 0px 0px 30px 0px;
	letter-spacing: -1px;
	text-shadow: 1px 1px 0px #FFFFFF;
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 200;
	color: #93776E;
}

#footer-content a {
}

#footer-content a:hover {
	text-decoration: underline;
}


#column1 {
	float: left;
	width: 280px;
	margin-right: 30px;
}

#column1 p {
	line-height: 1;
}

#column2 {
	float: left;
	width: 280px;
	margin-right: 30px;
}

#column3 {
	float: left;
	width: 280px;
}

#column4 {
	float: right;
	width: 260px;
}


/* Banner Style */

#banner-wrapper {
}

#banner {
	overflow: hidden;
	width: 1160px;
	margin: 20px auto 50px auto;
}

#banner .img-border {
	height: 500px;
	border: 20px solid #FFFFFF;
}

/* Button Style */

.button-style {
	display: inline-block;
	margin-top: 30px;
	padding: 7px 30px;
	background: #1f31bb;
	border-radius: 5px;
}

.button-style a {
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	font-family: 'Oswald', sans-serif;
	font-weight: 300;
	font-size: 16px;
	color: #FFFFFF;
}

.button-style a:hover
{
	text-decoration: none;
	color: #FFF;
}

/* List Style 1 */

ul.style1 {
	margin: 0px;
	padding: 0px;
	list-style: none;
}

ul.style1 li {
	padding: 35px 0px 25px 0px;
	border-top: 1px solid #c8c8c8;
}

ul.style1 a {
	text-decoration: none;
	color: #FFFFFF;
}

ul.style1 a:hover {
	text-decoration: underline;
	color: #6B6B6B;
}

ul.style1 .first {
	padding-top: 0px;
	border-top: none;
}

ul.style1 h3
{
	padding-bottom: 20px;
	font-size: 20px;
	font-weight: bold;
}

ul.style1 .button-style a:hover
{
	text-decoration: none !important;
	color: #FFF !important;
}

/** LIST STYLE 3 */

ul.style3 {
	margin: 0px;
	padding: 0px;
	list-style: none;
}

ul.style3 li {
	padding: 10px 0px 10px 0px;
	border-top: 1px solid #c8c8c8;
}

ul.style3 a {
	text-decoration: none;
	color: #949494;
}

ul.style3 a:hover {
	text-decoration: underline;
}

ul.style3 .first {
	padding-top: 0px;
	border-top: none;
	box-shadow: none;
}

ul.style3 .date {
	width: 87px;
	border-radius: 5px;
	background-color: #1f31bb;
	margin-top: 20px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	font-size: 12px;
	color: #FFFFFF;
}

ul.style3 .first .date
{
	margin-top: 0px;
}


/* Recent News */

#recent-news {
	overflow: hidden;
	width: 1120px;
	margin: 0px auto;
	padding: 50px 40px;
	background: #36332E;
	color: #8C8C8C;
}

#recent-news h2 {
	padding: 0px 0px 20px 0px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 2.5em;
	color: #EDEDED;
}

#recent-news .date {
	padding: 0px 0px 10px 0px;
	font-weight: bold;
	font-style: normal;
	color: #E3E3E3;
}

#recent-news p {
	font-style: italic;
}

#recent-news a {
	font-style: normal;
	color: #B8B8B8;
}

#recent-news #rbox1 {
	float: left;
	width: 250px;
	margin-right: 40px;
}

#recent-news #rbox2 {
	float: left;
	width: 250px;
	margin-right: 40px;
}

#recent-news #rbox3 {
	float: left;
	width: 250px;
}

#recent-news #rbox4 {
	float: right;
	width: 250px;
}

/* Button Style */

.button-style1 {
	margin-top: 30px;
}

.button-style1 a {
	padding: 10px 25px;
	background: #1f31bb;
	border-radius: 5px;
	text-decoration: none;
	text-shadow: 1px 1px 0px #FCE3BB;
	color: #36332E !important;
}

#home {
	animation-duration: 2.0s;
}
#contents {
	font-size: 12px;
}